<template>
    <div class="DeviceDetail">
        <el-card class="box-card">
            <i class="el-icon-close cha" @click="$router.go(-1)"></i>

            <div class="clearfix">
                仪器信息
            </div>
            <div class="df">
                <div class="item">
                    <div class="label">仪器分类：</div>
                    <div class="info">{{ deviceCrkInfo.deviceOneClassDesc }}</div>
                </div>
                <div class="item">
                    <div class="label">仪器名称：</div>
                    <div class="info">{{ deviceCrkInfo.deviceName }}</div>
                </div>
                <div class="item">
                    <div class="label">实际名称：</div>
                    <div class="info">{{ deviceCrkInfo.deviceRealName }}</div>
                </div>
            </div>
            <div class="df">
                <div class="item">
                    <div class="label">仪器型号：</div>
                    <div class="info">{{ deviceCrkInfo.deviceType }}</div>
                </div>
                <div class="item">
                    <div class="label">仪器编号：</div>
                    <div class="info">{{ deviceCrkInfo.deviceNum }}</div>
                </div>
                <div class="item">
                    <div class="label">仪器条码:</div>
                    <div class="info">{{ deviceCrkInfo.deviceCode }}</div>
                </div>
            </div>
        </el-card>

        <el-card>
            <div class="clearfix">
                出库信息
            </div>
            <div class="df">
                <div class="item">
                    <div class="label">批次号：</div>
                    <div class="info">{{ deviceCrkInfo.oddNum }}</div>
                </div>
                <div class="item">
                    <div class="label">入库类型：</div>
                    <div class="info">{{ deviceCrkInfo.crkTypeDesc }}</div>
                </div>
                <div class="item"  style="font-weight: 700;">
                    <div class="label">出入库状态：</div>
                    <div class="info">{{ deviceCrkInfo.status_text }}</div>
                </div>

            </div>
            <div v-if="crkType === '00'">
                <div class="df">
                    <div class="item">
                        <div class="label">试验日期：</div>
                        <div class="info">{{ deviceCrkInfo.testDate }}</div>
                    </div>
                    <div class="item">
                        <div class="label">试验类别：</div>
                        <div class="info">{{ deviceCrkInfo.testType }}</div>
                    </div>
                    <div class="item">
                        <div class="label">站所：</div>
                        <div class="info">{{ deviceCrkInfo.station }}</div>
                    </div>
                </div>
                <div class="df">
                    <div class="item">
                        <div class="label">间隔：</div>
                        <div class="info">{{ deviceCrkInfo.intervalDesc }}</div>
                    </div>
                    <div class="item">
                        <div class="label">试验性质：</div>
                        <div class="info">{{ deviceCrkInfo.testNature }}</div>
                    </div>
                    <div class="item">
                        <div class="label">使用期期限：</div>
                        <div class="info">{{ deviceCrkInfo.useDeadline }}</div>
                    </div>
                </div>
                <div class="df">
                    <div class="item">
                        <div class="label">试验负责人：</div>
                        <div class="info">{{ deviceCrkInfo.testFzr }}</div>
                    </div>
                    <div class="item">
                        <div class="label">试验人员：</div>
                        <div class="info">{{ deviceCrkInfo.tester }}</div>
                    </div>
                    <div class="item">
                        <div class="label">出库经办人：</div>
                        <div class="info">{{ deviceCrkInfo.ckJbr }}</div>
                    </div>
                </div>
                <div class="df">
                    <div class="item">
                        <div class="label">出库日期：</div>
                        <div class="info">{{ deviceCrkInfo.ckDate }}</div>
                    </div>
                    <div class="item">
                        <div class="label">出库时间：</div>
                        <div class="info">{{ deviceCrkInfo.ckTime }}</div>
                    </div>
                    <div class="item">
                        <div class="label">延期入库原因：</div>
                        <div class="info">{{ deviceCrkInfo.reason }}</div>
                    </div>
                </div>
            </div>
            <div v-if="crkType === '01'">
                <div class="df">
                    <div class="item">
                        <div class="label">外送日期：</div>
                        <div class="info">{{ deviceCrkInfo.wsDate }}</div>
                    </div>
                    <div class="item">
                        <div class="label">外送人员：</div>
                        <div class="info">{{ deviceCrkInfo.wsPersonnel }}</div>
                    </div>
                    <div class="item">
                        <div class="label">外送方式：</div>
                        <div class="info">{{ deviceCrkInfo.wsWay }}</div>
                    </div>
                </div>
                <div class="df">
                    <div class="item">
                        <div class="label">是否携带附件：</div>
                        <div class="info">{{ deviceCrkInfo.isXdfj === 0 ? '是' : '否' }}</div>
                    </div>
                    <div class="item">
                        <div class="label">接受单位：</div>
                        <div class="info">{{ deviceCrkInfo.jsUnit }}</div>
                    </div>
                    <div class="item">
                        <div class="label">接受人员：</div>
                        <div class="info">{{ deviceCrkInfo.jsPersonnel }}</div>
                    </div>
                </div>
                <div class="df">
                    <div class="item">
                        <div class="label">联系方式：</div>
                        <div class="info">{{ deviceCrkInfo.contactWay }}</div>
                    </div>
                    <div class="item">
                        <div class="label">出库经办人：</div>
                        <div class="info">{{ deviceCrkInfo.ckJbr }}</div>
                    </div>
                    <div class="item">
                        <div class="label">出库日期：</div>
                        <div class="info">{{ deviceCrkInfo.ckDate }}</div>
                    </div>
                </div>
                <div class="df">
                    <div class="item">
                        <div class="label">出库时间：</div>
                        <div class="info">{{ deviceCrkInfo.ckTime }}</div>
                    </div>
                </div>
            </div>
            <div v-if="crkType === '02'">
                <div class="df">
                    <div class="item">
                        <div class="label">预警期：</div>
                        <div class="info">{{ deviceCrkInfo.checkWaring_text }}</div>
                    </div>
                    <div class="item">
                        <div class="label">原因：</div>
                        <div class="info">{{ deviceCrkInfo.reason }}</div>
                    </div>
                    <div class="item">
                        <div class="label">外送日期：</div>
                        <div class="info">{{ deviceCrkInfo.wsDate }}</div>
                    </div>

                </div>
                <div class="df">
                    <div class="item">
                        <div class="label">外送人员：</div>
                        <div class="info">{{ deviceCrkInfo.wsPersonnel }}</div>
                    </div>
                    <div class="item">
                        <div class="label">外送方式：</div>
                        <div class="info">{{ deviceCrkInfo.wsWay }}</div>
                    </div>
                    <div class="item">
                        <div class="label">是否携带附件：</div>
                        <div class="info">{{ deviceCrkInfo.isXdfj === 0 ? '是' : '否' }}</div>
                    </div>
                </div>
                <div class="df">
                    <div class="item">
                        <div class="label">接受单位：</div>
                        <div class="info">{{ deviceCrkInfo.jsUnit }}</div>
                    </div>
                    <div class="item">
                        <div class="label">接受人员：</div>
                        <div class="info">{{ deviceCrkInfo.jsPersonnel }}</div>
                    </div>
                    <div class="item">
                        <div class="label">联系方式：</div>
                        <div class="info">{{ deviceCrkInfo.contactWay }}</div>
                    </div>

                </div>
                <div class="df">
                    <div class="item">
                        <div class="label">出库经办人：</div>
                        <div class="info">{{ deviceCrkInfo.ckJbr }}</div>
                    </div>
                    <div class="item">
                        <div class="label">出库日期：</div>
                        <div class="info">{{ deviceCrkInfo.ckDate }}</div>
                    </div>
                    <div class="item">
                        <div class="label">出库时间：</div>
                        <div class="info">{{ deviceCrkInfo.ckTime }}</div>
                    </div>
                </div>
            </div>

            <div v-if="crkType === '03'">
                <div class="df">
                    <div class="item">
                        <div class="label">借出日期：</div>
                        <div class="info">{{ deviceCrkInfo.jcDate }}</div>
                    </div>
                    <div class="item">
                        <div class="label">借出期限：</div>
                        <div class="info">{{ deviceCrkInfo.jcDeadline }}</div>
                    </div>
                    <div class="item">
                        <div class="label">借出人员：</div>
                        <div class="info">{{ deviceCrkInfo.jcPersonnel }}</div>
                    </div>
                </div>
                <div class="df">
                    <div class="item">
                        <div class="label">审批人员：</div>
                        <div class="info">{{ deviceCrkInfo.approvePersonnel }}</div>
                    </div>
                    <div class="item">
                        <div class="label">是否携带附件：</div>
                        <div class="info">{{ deviceCrkInfo.isXdfj === 0 ? '是' : '否' }}</div>
                    </div>
                    <div class="item">
                        <div class="label">接受单位：</div>
                        <div class="info">{{ deviceCrkInfo.jsUnit }}</div>
                    </div>
                </div>
                <div class="df">
                    <div class="item">
                        <div class="label">接受人员：</div>
                        <div class="info">{{ deviceCrkInfo.jsPersonnel }}</div>
                    </div>
                    <div class="item">
                        <div class="label">联系方式：</div>
                        <div class="info">{{ deviceCrkInfo.contactWay }}</div>
                    </div>
                    <div class="item">
                        <div class="label">出库经办人：</div>
                        <div class="info">{{ deviceCrkInfo.ckJbr }}</div>
                    </div>
                </div>
                <div class="df">
                    <div class="item">
                        <div class="label">出库日期：</div>
                        <div class="info">{{ deviceCrkInfo.ckDate }}</div>
                    </div>
                    <div class="item">
                        <div class="label">出库时间：</div>
                        <div class="info">{{ deviceCrkInfo.ckTime }}</div>
                    </div>
                </div>
            </div>

            <div v-if="crkType === '04'">
                <div class="df">
                    <div class="item">
                        <div class="label">转移日期：</div>
                        <div class="info">{{ deviceCrkInfo.zyDate }}</div>
                    </div>
                    <div class="item">
                        <div class="label">转移人员：</div>
                        <div class="info">{{ deviceCrkInfo.zyPersonnel }}</div>
                    </div>
                    <div class="item">
                        <div class="label">目标库房：</div>
                        <div class="info">{{ deviceCrkInfo.mbkf }}</div>
                    </div>
                </div>
                <div class="df">
                    <div class="item">
                        <div class="label">目标位置：</div>
                        <div class="info">{{ deviceCrkInfo.mbLocation }}</div>
                    </div>
                    <div class="item">
                        <div class="label">转移原因：</div>
                        <div class="info">{{ deviceCrkInfo.zyCause }}</div>
                    </div>
                    <div class="item">
                        <div class="label">出库经办人：</div>
                        <div class="info">{{ deviceCrkInfo.ckJbr }}</div>
                    </div>
                </div>
                <div class="df">
                    <div class="item">
                        <div class="label">出库日期：</div>
                        <div class="info">{{ deviceCrkInfo.ckDate }}</div>
                    </div>
                    <div class="item">
                        <div class="label">出库时间：</div>
                        <div class="info">{{ deviceCrkInfo.ckTime }}</div>
                    </div>
                </div>
            </div>
        </el-card>

        <el-card v-if="deviceCrkInfo.rkDate">
            <div class="clearfix">
                入库信息
            </div>
            <div class="df">
                <div class="item">
                    <div class="label">批次号：</div>
                    <div class="info">{{ deviceCrkInfo.oddNum }}</div>
                </div>
                <div class="item">
                    <div class="label">入库类型：</div>
                    <div class="info">{{ deviceCrkInfo.crkTypeDesc }}</div>
                </div>
                <div class="item" style="font-weight: 700;">
                    <div class="label">出入库状态：</div>
                    <div class="info">{{ deviceCrkInfo.status_text }}</div>
                </div>


            </div>

            <div v-if="crkType === '00'">
                <div class="df">
                    <div class="item">
                        <div class="label">入库经办人：</div>
                        <div class="info">{{ deviceCrkInfo.rkJbr }}</div>
                    </div>
                    <div class="item">
                        <div class="label">入库日期：</div>
                        <div class="info">{{ deviceCrkInfo.rkDate }}</div>
                    </div>
                    <div class="item">
                        <div class="label">入库经时间：</div>
                        <div class="info">{{ deviceCrkInfo.rkTime }}</div>
                    </div>
                </div>

            </div>
            <div v-if="crkType === '01'">
                <div class="df">
                    <div class="item">
                        <div class="label">外观是否正常：</div>
                        <div class="info">{{ deviceCrkInfo.isWgjcOk === 0 ? '正常' : '异常' }}</div>
                    </div>
                    <div class="item">
                        <div class="label">性能检测：</div>
                        <div class="info">{{ deviceCrkInfo.xnCheck_text }}</div>
                    </div>
                    <div class="item">
                        <div class="label">入库经办人：</div>
                        <div class="info">{{ deviceCrkInfo.rkJbr }}</div>
                    </div>
                </div>
                <div class="df">
                    <div class="item">
                        <div class="label">入库日期：</div>
                        <div class="info">{{ deviceCrkInfo.rkDate }}</div>
                    </div>
                    <div class="item">
                        <div class="label">入库经时间：</div>
                        <div class="info">{{ deviceCrkInfo.rkTime }}</div>
                    </div>
                </div>
            </div>

            <div v-if="crkType === '02'">
                <div class="df">
                    <div class="item">
                        <div class="label">是否合格：</div>
                        <div class="info">{{ deviceCrkInfo.isCheckoutOk === 0 ? '合格' : '不合格' }}</div>
                    </div>
                    <div class="item">
                        <div class="label">{{ deviceCrkInfo.isCheckoutOk === 0 ? '合格' : '不合格' }}标签：</div>
                        <!-- <div class="info">{{ deviceCrkInfo.xyTag }}</div> -->
                        <!-- <div class="info">
                            <el-image style="width: 100px; height: 100px" :src="path + deviceInfo.xyTag"
                                fit="cover"></el-image>
                        </div> -->
                        <div class="info" v-for=" (item, index) in xyTagList" :key="index">
                            <el-image style="width: 100px; height: 100px" :src="item" :preview-src-list="xyTagList"
                                fit="cover"></el-image>
                        </div>
                    </div>
                    <div class="item">
                        <div class="label">{{ deviceCrkInfo.isCheckoutOk === 0 ? '合格' : '不合格' }}证书：</div>
                        <div class="info">
                            <a target="_blank" :href="path + deviceCrkInfo.xyCerPath">{{ deviceCrkInfo.xyCer }}</a>
                        </div>
                    </div>
                </div>
                <div class="df">
                    <div class="item">
                        <div class="label">外观是否正常：</div>
                        <div class="info">{{ deviceCrkInfo.isWgjcOk === 0 ? '正常' : '异常' }}</div>
                    </div>
                    <div class="item">
                        <div class="label">性能检测：</div>
                        <div class="info">{{ deviceCrkInfo.xnCheck_text }}</div>
                    </div>
                    <div class="item">
                        <div class="label">入库经办人：</div>
                        <div class="info">{{ deviceCrkInfo.rkJbr }}</div>
                    </div>
                </div>
                <div class="df">
                    <div class="item">
                        <div class="label">入库日期：</div>
                        <div class="info">{{ deviceCrkInfo.rkDate }}</div>
                    </div>
                    <div class="item">
                        <div class="label">入库经时间：</div>
                        <div class="info">{{ deviceCrkInfo.rkTime }}</div>
                    </div>
                </div>
            </div>

            <div v-if="crkType === '03'">
                <div class="df">
                    <div class="item">
                        <div class="label">外观是否正常：</div>
                        <div class="info">{{ deviceCrkInfo.isWgjcOk === 0 ? '正常' : '异常' }}</div>
                    </div>
                    <div class="item">
                        <div class="label">性能检测：</div>
                        <div class="info">{{ deviceCrkInfo.xnCheck_text }}</div>
                    </div>
                    <div class="item">
                        <div class="label">入库经办人：</div>
                        <div class="info">{{ deviceCrkInfo.rkJbr }}</div>
                    </div>
                </div>
                <div class="df">
                    <div class="item">
                        <div class="label">入库日期：</div>
                        <div class="info">{{ deviceCrkInfo.rkDate }}</div>
                    </div>
                    <div class="item">
                        <div class="label">入库经时间：</div>
                        <div class="info">{{ deviceCrkInfo.rkTime }}</div>
                    </div>
                </div>
            </div>

            <div v-if="crkType === '04'">
                <div class="df">
                    <div class="item">
                        <div class="label">入库经办人：</div>
                        <div class="info">{{ deviceCrkInfo.rkJbr }}</div>
                    </div>
                    <div class="item">
                        <div class="label">入库日期：</div>
                        <div class="info">{{ deviceCrkInfo.rkDate }}</div>
                    </div>
                    <div class="item">
                        <div class="label">入库经时间：</div>
                        <div class="info">{{ deviceCrkInfo.rkTime }}</div>
                    </div>
                </div>

            </div>
        </el-card>

        <el-card v-if="crkType === '01' || crkType === '02' || crkType === '03'">
            <div class="clearfix">
                外观图片
            </div>
            <div class="df-img">

                <div class="info" v-if="imgsList">
                    <div class="block" v-for="(item, index) in imgsList" :key="index">
                        <!-- <span class="demonstration">自定义</span> -->
                        <el-image :src="item" :preview-src-list="imgsList">
                            <div slot="placeholder" class="image-slot">
                                加载中<span class="dot">...</span>
                            </div>
                        </el-image>
                    </div>
                </div>
            </div>
        </el-card>

    </div>
</template>

<script>
import { crkStatusList } from '@/api/device.js'
import { queryCrkInfo, crkTypeList, rkTypeList } from '@/api/deviceCrk.js'
export default {
    props: {
        id: {
            type: [String, Number]
        },
        type: {
            type: [String, Number]
        }
    },
    data() {
        return {
            crkStatusList: crkStatusList,
            crkType: null,
            deviceCrkInfo: {},
            xnMaintainList: [{ code: '00', name: '修后性能正常' }, { code: '06', name: '修后性能待查' }, { code: '12', name: '修后性能异常' }],
            xnCheckList: [{ code: '00', name: '校后性能正常' }, { code: '07', name: '校后性能待查' }, { code: '13', name: '校后性能异常' }],
            xnBackList: [{ code: '00', name: '还后性能正常' }, { code: '08', name: '还后性能待查' }, { code: '14', name: '还后性能异常' }],
            crkTypeList: crkTypeList,
            rkTypeList: rkTypeList,
            imgsList: [],
            xyTagList: [],
            path: process.env.VUE_APP_URL + this.UploadPath

        }
    },
    mounted() {
        this.id && this.loadDeviceCRKInfo()
    },
    methods: {
        //根据id查询设备信息
        async loadDeviceCRKInfo() {
            await queryCrkInfo(this.id, this.type).then(res => {
                if (res.code === '000000') {
                    this.deviceCrkInfo = res.t
                    const index2 = this.crkStatusList.findIndex(item2 => this.deviceCrkInfo.status === item2.code);
                    if (index2 != -1) {
                        this.deviceCrkInfo.status_text = this.crkStatusList[index2].name
                    }
                    this.xyTagList.push(this.path + this.deviceCrkInfo.xyTag)
                    this.crkType = res.t.crkType
                    if (this.deviceCrkInfo.checkWaring) {
                        this.deviceCrkInfo.checkWaring === '00' ? this.deviceCrkInfo.checkWaring_text = '提前校验' : (this.deviceCrkInfo.checkWaring === '01' ? this.deviceCrkInfo.checkWaring_text = '校验预警期' : this.deviceCrkInfo.checkWaring_text = '超期校验')
                    }

                    switch (this.crkType) {
                        case '01':
                            var mindex = this.xnMaintainList.findIndex(item => item.code === res.t.xnCheck)
                            if (mindex !== -1) {
                                this.deviceCrkInfo.xnCheck_text = this.xnMaintainList[mindex].name
                            }

                            break;
                        case '02':
                            var cindex = this.xnCheckList.findIndex(item => item.code === res.t.xnCheck)
                            if (cindex !== -1) {
                                this.deviceCrkInfo.xnCheck_text = this.xnMaintainList[cindex].name
                            }
                            break;
                        case '03':
                            var bindex = this.xnCheckList.findIndex(item => item.code === res.t.xnCheck)
                            if (bindex !== -1) {
                                this.deviceCrkInfo.xnCheck_text = this.xnMaintainList[bindex].name
                            }
                            break;
                        default:
                            this.deviceCrkInfo.xnCheck_text = ''
                            break;
                    }

                    if (this.deviceCrkInfo.wgjcImage != null && this.deviceCrkInfo.wgjcImage != '') {
                        const list2 = this.deviceCrkInfo.wgjcImage.split(";")
                        list2.forEach(item => {
                            if (item != '' && item != null) {
                                const obj = this.path + item
                                this.imgsList.push(obj)
                            }
                        })
                    }
                    const ckindex = this.crkTypeList.findIndex(item => item.code === this.deviceCrkInfo.crkType)
                    if (ckindex !== -1) {
                        this.deviceCrkInfo.crkTypeName = this.crkTypeList[ckindex].name
                    }
                    const rkindex = this.rkTypeList.findIndex(item => item.code === this.deviceCrkInfo.crkType)
                    if (rkindex !== -1) {
                        this.deviceCrkInfo.crkTypeDesc = this.rkTypeList[rkindex].name
                    }
                    console.log(this.deviceCrkInfo)
                }
            })
        },

    }
}

</script>


<style lang="scss" scoped>
.DeviceDetail {
    .el-card {
        position: relative;
        text-align: left;
        margin-bottom: 15px;

        .cha {
            position: absolute;
            top: 5px;
            right: 5px;
            font-size: 30px;
            color: rgba(54, 55, 56, 0.445);
        }

        .cha:hover {
            cursor: pointer;
            color: #2a2d2f;
        }
    }

    .clearfix {
        margin-bottom: 20px;
        font-size: 16px;
        color: #1d2129;
    }


    .el-icon-error {
        font-size: 20px;
    }

    .el-icon-error:hover {
        color: red;
    }

    .df:last-child {
        margin-bottom: 0;
    }

    .df {
        display: flex;
        margin-bottom: 16px;

        .item {
            width: 33.333333%;
            display: flex;
            font-size: 14px;
            color: #4e5969;

            .label {
                margin-right: 8px;
            }

            .info {
                // width: 100%;
                float: left;
            }
        }
    }

    .df-img {
        width: 100%;
        margin-bottom: 16px;

        .info {
            width: 100%;
            // float: left;
            display: flex;

            .block {
                width: 25%;
                margin-right: 10px;

                .el-image {
                    width: 100%;
                    height: 100%;
                }
            }

        }
    }


}
</style>